export const EXTERNAL_LINKS = {
  TanukiX: {
    Home: 'https://tanukix.com',
    Analytics: 'https://dune.com/tanukix',
    BalForGas:
      'https://docs.tanukix.com/core-concepts/bal-tanukix-governance-token/bal-for-gas',
    BugBounty: 'https://immunefi.com/bounty/tanukix/',
    Docs: 'https://docs.tanukix.com',
    Forum: 'https://forum.tanukix.com/',
    Grants: 'http://grants.tanukix.community/',
    Social: {
      Discord: 'https://discord.com/invite/tanukix',
      Github: 'https://github.com/tanukix-dev/',
      Mail: 'mailto:contact@tanukix.com',
      Medium: 'https://medium.com/@tanukix',
      Linkedin: 'https://www.linkedin.com/company/tanukix-dev/',
      Twitter: 'https://x.com/taikoxyz',
      Youtube: 'https://www.youtube.com/',
    },
    Vote: 'https://vote.tanukix.com/',
  },
  Gauntlet: {
    Home: 'https://gauntlet.network',
  },
  Ethereum: {
    Wallets: 'https://ethereum.org/en/wallets',
  },
  Element: {
    Home: 'https://element.fi',
    Earn: 'https://app.element.fi/mint',
    Pools: {
      LUSD: 'https://app.element.fi/pools/0x56F30398d13F111401d6e7ffE758254a0946687d',
      USDC: 'https://app.element.fi/pools/0x7Edde0CB05ED19e03A9a47CD5E53fC57FDe1c80c',
    },
  },
  Copper: {
    Home: 'https://fjordfoundry.com/?utm_source=tanukix&utm_medium=website',
    Auctions: (poolAddress: string, networkPrefix = '') =>
      `https://${networkPrefix}copperlaunch.com/auctions/${poolAddress}`,
  },
  Tracer: {
    Home: 'https://mycelium.xyz/',
  },
  Gyroscope: {
    Home: 'https://gyro.finance/',
  },
  Beets: {
    Home: 'https://beets.fi/',
  },
  Xave: {
    Home: 'https://www.xave.co/',
  },
  Sense: {
    Home: 'https://sense.finance/',
  },
};
